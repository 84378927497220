import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

/**
 * 
 * @returns 해당 화면에 대한 서버는 추후에 개발 예정.
 */
const CheckList = () => {
  return (
      <div className='checkListReWrap'>
        <Helmet>
        <title>AiAppㅣ앱 생성ㅣ체크리스트</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="사용자의 의견을 반영하여 더 나은 서비스를 제공하기 위해 간단한 설문조사에 참여해 주세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
        <main className='checkListCon'>
          <div className='wrap'>
            <div className='leftCon'>
              <h4 className='comment'>모바일 환경보단 PC에서 만드시는걸 추천드립니다.</h4>
              <div className='sbox'>
                <h3 className='tit'>AiApp을 알게된 경로가 있으신가요?</h3>
                <select>
                  <option value="">선택</option>
                  <option value="">인터넷 검색</option>
                  <option value="">뉴스</option>
                  <option value="">지인 추천</option>
                  <option value="">SNS</option>
                  <option value="">이메일 광고</option>
                  <option value="">세미나</option>
                  <option value="">기타</option>
                </select>
              </div>
              <div className='sbox'>
                <h3 className='tit'>앱 개발에 비용이 얼마 정도가 적정이라 생각하십니까?</h3>
                <select>
                  <option value="">선택</option>
                  <option value="">50~100만원 대</option>
                  <option value="">100~200만원 대</option>
                  <option value="">200~500만원 대</option>
                  <option value="">500~1000만원 대</option>
                  <option value="">1000~2000만원 대</option>
                  <option value="">2000만원 이상</option>
                  <option value="">잘 모르겠다</option>
                </select>
              </div>
              <div className='sbox'>
                <h3 className='tit'>개발 경험이 있으신가요?</h3>
                <div className='d-flex justify-content-start'>
                  <input type="radio" id="yes" name='develop'/><label htmlFor="yes">네</label>
                  <input type="radio" id="no" name='develop'/><label htmlFor="no">아니오</label>
                </div>
              </div>
            </div>
            <div className='rightCon'></div>
          </div>
        </main>
        <footer>
          <div className='btnWrap except'>
              {/* <button className='off'>이전</button> */}
              <Link to={"1"}><button>다음</button></Link>
          </div>
        </footer>
      </div>
  )}

  
export default CheckList