import React from 'react'
import { Helmet } from 'react-helmet-async';


/**
 * 아이디 찾기 완료 페이지
 * @returns 
 */
const FindIdCom = () => {

  return (
    <main>
      <Helmet>
        <title>AiAppㅣ아이디 찾기 완료</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="아이디 찾기 완료 페이지입니다. 성공적으로 계정 정보를 확인하셨습니다. 이제 로그인하여 서비스를 자유롭게 이용하세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="joinWrap">
        <div className="joinCon">
          <h1>Find ID</h1>
          <div className='result'>
            <p>입력하신 정보와 일치하는 아이디 입니다.</p>
            <div>aa***@aaa.com</div>
            {/* <div>일치하는 정보가 없습니다.</div> */}
          </div>
          <button>로그인 하러가기</button>
          <div className='or'>
            <p className='center'>비밀번호를 잃어버리셨나요?</p>
            <button>비밀번호 찾기</button>
          </div>
        </div>
      </div>
    </main>
  )

}

export default FindIdCom