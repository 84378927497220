import React from 'react';

const GeneratingScreen = ({ message }) => {
  return (
    <div className="generating-screen">
      <div className="generating-content">
        <img 
          src={`${process.env.PUBLIC_URL}/img/aiapp_logoB.svg`} 
          alt="aiapp 로고이미지" 
          className="ai-icon"
        />
        <div className="generating-text">
          <p className="title">{message}</p>
          <div className="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratingScreen; 