import React from 'react';

const ConfirmPopup = ({ isOpen, onClose, onConfirm, title, message }) => {
  if (!isOpen) return null;

  const handleConfirm = async () => {
    onClose(); // 팝업창 먼저 닫기
    onConfirm(); // 확인 동작 실행
  };

  return (
    <div className="popupWrap" style={{ display: 'block' }}>
      <div className="popupBox" style={{ minWidth: '400px' }}>
        <div className="d-flex justify-content-between align-items-center popTit">
          <h2>{title}</h2>
          <button className="closeBtn" onClick={onClose}>
            <img src={`${process.env.PUBLIC_URL}/img/icon_close.png`} alt="닫기" />
          </button>
        </div>
        <p>{message}</p>
        <div className="d-flex justify-content-center gap10 popBtnWrap">
          <button onClick={onClose}>취소</button>
          <button className="on" onClick={handleConfirm}>확인</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup; 