import React from 'react';
import ReactDOM from 'react-dom/client';
import "./css/reset.css"
import "./css/font.css"
import "./css/common.css"
import "./css/media.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { createRouter } from './routers/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';


const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
    }
  }
});
/**
 * Recoil, React-query, React-dom-router 사용설정.
 */
root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <RouterProvider router={createRouter(queryClient)} /> 
      </HelmetProvider>
    </QueryClientProvider>
    
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
