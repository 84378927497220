import React from 'react'
import { Link, useLoaderData, useRevalidator, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

/**
 * 
 * @returns 화면에 나오는 "교회"라는 문구는 현재 하드코딩 되어있음. 앱유형을 파라미터로 넘기려고하니 인코딩 에러남.
 */
const CheckList2 = () => {
  const [searchParams,] = useSearchParams();
  const { data } = useLoaderData();
  const revalidator = useRevalidator();

  return (
    <div className='checkListReWrap'>
      <Helmet>
        <title>AiAppㅣ앱 생성ㅣ체크리스트 2</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="앱 생성 체크리스트 두번째 단계입니다. 유사한 앱 정보를 확인하고 성공적인 앱 개발을 위한 인사이트를 얻어보세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <main className='checkListCon'>
        <div className='wrap'>
          <div className='centerCon'>
            <h2>교회와/과 비슷한 앱 정보가 궁금하셨나요?<br />Ai가 추천해드리는 유사앱 정보를 참고해보세요.</h2>
            <ul className='similarWrap'>
              {
                data.map(d => (
                  <li key={d.code}>
                    <img src={d.icon} alt="앱아이콘 이미지" />
                    <h3>{d.name}</h3>
                    <p>{d.content}</p>
                    <div className='link'>
                      <button>앱 보러가기</button>
                      <img src="../img/icon_linkB.png" alt="링크 아이콘" />
                    </div>
                  </li>
                ))
              }
            </ul>
            <button className='moreBtn' onClick={() => { revalidator.revalidate() }}>더보기 <img src="../img/icon_refreshB.png" alt="새로고침 아이콘" /></button>
          </div>
        </div>
      </main>
      <footer>
        <div className='btnWrap'>
          <Link to={-1}><button className='off'>이전</button></Link>
          <Link to={`/checklist/3?${searchParams.toString()}`}><button>다음</button></Link>
        </div>
      </footer>
    </div>
  )
}


export default CheckList2