import { POST_METHOD } from "../../const/const";
import { CHANGE_PLAN_DEFINE, GENERATE_PLAN_DEFINE, ROLLBACK_PLAN_DEFINE } from "../../const/defineName";

export const builderDefine = {
    [GENERATE_PLAN_DEFINE]: {
        method: POST_METHOD,
        url: "/webview/generate"
    },
    [ROLLBACK_PLAN_DEFINE]: {
        method: POST_METHOD,
        url: "/webview/rollback"
    },
    [CHANGE_PLAN_DEFINE]: {
        method: POST_METHOD,
        url: "/webview/change"
    },
 

}