import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const StoryDetail = () => {
  return (
    <main>
      <Helmet>
        <title>AiAppㅣ스토리 상세</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="최신 소식, 사용자 인터뷰, 공지사항 등 다양한 정보를 확인하고, 앱 개발에 대한 유용한 인사이트를 얻어보세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <section class="storyConWrap bg3">
        <div class="width14">
          <div class="storydetail">
            <p>
              Notice
            </p>
            <h2 >Aiapp에 오신걸 환영합니다.</h2>
            <div class="storyTxt">
              <div className='storyImg'>
                <img src="../../img/story0.png" alt="스토리 이미지" />
              </div>
              <pre>
                <h2>앱 제작의 첫걸음 기획부터, 앱 개발까지 모두 한번에!</h2>
                <p>안녕하세요, 고객 여러분. <br/>저희 Aiapp에서 새롭게 서비스를 오픈하게 되어 기쁜 마음으로 안내드립니다.</p><br/><br/>
                <p className='bold'>서비스 오픈 일시:</p>
                <p>&#45; 2024년 5월 27일 </p><br/>
                <p className='bold'>서비스 소개 :  </p>
                <p>&#45; AiApp은 고객 여러분께 간편하고 쉽게 앱을 생성할 수 있는 서비스로, 다른 앱빌드 서비스와는 다르게 기획서를 통해 앱을 수정하여 자신만의 앱을 가지실 수 있습니다.</p> <br/>
                <p className='bold'>주요기능 :  </p>
                <ul>
                  <li>&#45; 쉽고 간편한 무료 앱생성</li>
                  <li>&#45; 다양한 템플릿</li>
                  <li>&#45; 기획서를 통한 나만의 앱제작</li>
                  <li>&#45; 앱에 필요한 다양한 부가서비스 지원</li>
                </ul>
                <br/>
                <p className='bold'>이용방법 :  </p>
                <p>홈페이지에 접속하신 후, 회원가입하고 '앱생성 하러가기' 탭을 클릭하세요.</p><br/>
                <p className='bold'>문의사항 :  </p>
                <p>서비스 이용에 관한 문의는 mbaas.help@gmail.com 으로 연락해주시기 바랍니다</p><br/><br/>
                <p className='bold'>고객 여러분의 많은 관심과 성원을 부탁드리며, AiApp을 통해 더 나은 서비스를 제공할 수 있도록 노력하겠습니다.<br/>감사합니다.</p>

              </pre>
            </div>
            <Link to={-1}><div class="listBtn" ><button>목록으로</button></div></Link>
          </div>
        </div>
      </section>
    </main>
  )
}

export default StoryDetail