import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate();
    const moving = (url) => {
        window.open(url, "_blank", "noopener, noreferrer");
      };
    
  return (
    <footer>
        <div className="footer_wrap width14">
            <div className="footer_top">
                <div className="footer_left">
                    <div className="left">
                        <div className='footer_logo'>
                            <img src="../img/aiapp_logoW.svg" alt="로고아이콘" />
                        </div>
                        <div className='d-flex gap10'>
                            <div>
                                <div className="content">
                                    <span>CEO.</span>
                                    <p>김정현</p>
                                </div>
                                <div className="content">
                                    <span>E-mail.</span>
                                    <p>mbaas.help@gmail.com</p>
                                </div>
                                <div className="content">
                                    <span>Address.</span>
                                    <p>Head Office.부산광역시 연제구 연제로 24, 204호</p>
                                    <p>R&D Center.부산광역시 연제구 연제로 24, 203호</p>
                                </div>
                            </div>
                            <div>
                                <div className="content">
                                    <span>Business Reg No.</span>
                                    <p>128-88-02089</p>
                                </div>
                                <div className="content">
                                    <span>Web Site.</span>
                                    <p className='cursor'  onClick={() => moving("https://mbaas.kr/")}>mbaas.kr</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_right cursor">
                    <div className="sns">
                        <div onClick={() => moving("https://blog.naver.com/mbaas")}></div>
                        <div onClick={() => moving("https://www.facebook.com/people/mBaaSInc/100078637600952/")}></div>
                        <div onClick={() => moving("https://www.youtube.com/@TV-re8xn")}></div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className='d-flex gap30'>
                    <Link to={"/serviceTerm"}><p>서비스 이용방침</p></Link>
                    <Link to={"/personalTerm"}><p>개인정보 처리방침</p></Link>
                </div>
                <div className="copy">Copyright © 2024 mBaaS all right reserved</div>

            </div>
        </div>
    </footer>
  )
}

export default Footer