import React, { useState } from 'react'
import { Form, Link, redirect, useLoaderData, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import { appUseQuery } from '../../../queries/checklistQueries';
import { POST_METHOD } from '../../../const/const';
import { makeParamsFromFormData } from '../../../util/util';
import { Helmet } from 'react-helmet-async';

/**
 * 해당 페이지가 랜더링 되기전 Loader가 실행되어 데이터 통신을 마친후 페이지를 랜더링 해준다.
 * @param {QueryClient} queryClient router.js에서 받아온 React-query Client
 * @returns 
 */
export const customTemplateLoader = 
  (queryClient) => async({ request }) => {
    const url = new URL(request.url);
    const category = url.searchParams.get("n");

    let json = {};
    if (!queryClient.getQueryData(appUseQuery().queryKey)) {
      json = await queryClient.fetchQuery(appUseQuery());
    } else {
      json = queryClient.getQueryData(appUseQuery().queryKey);
    }

    const list = json.data.customList;
    let result = [];
    for(let i = 0; i < list.length; i++) {
      if(list[i].category === category) {
        result = list[i].templateList;
        break;
      }
    }

    return result;
  }

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns redirect를 return 시킴으로써 해당 URL로 바로 이동시킨다.
 */
export const customTemplateAction = 
  () => async({ request }) => {
    const url = new URL(request.url);
    const category = url.searchParams.get("n");

    const param = makeParamsFromFormData(await request.formData());
    return redirect(`/checklistcustom/4?n=${category}&t=${param.appuse}`)
  }

const CheckList3_1 = () => {
  const [searchParams,] = useSearchParams();
  const data = useLoaderData();
  const [temImgs, setTemImgs] = useState(data[0].fileUrl);
  // slick 설정
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const onClickTemplate = (idx) => {
    setTemImgs(data[idx].fileUrl)
  }

  return (
    <div className='checkListReWrap'>
      <Helmet>
        <title>AiAppㅣ앱 생성ㅣ체크리스트 3-1</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="앱 생성 체크리스트 세번째 단계입니다. 다양한 템플릿 중에서 원하는 디자인을 선택하여 앱의 기본 구조를 설정하세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <Form method={POST_METHOD}>
      <main className='checkListCon'>
        <div className='wrap'>
          <div className='leftCon'>
            <div className='sbox'>
              <span>{searchParams.get("n")}을 선택하셨습니다.</span>
              <h2>템플릿을 선택해주세요.</h2>
              <p>모바일 환경에서는 템플릿 미리보기 지원이 되지않습니다.</p>
              <div className='custom appuseWrap'>
                <ul>
                  {
                    data.map((d, idx) => (
                      <li key={d.code} onClick={() => {onClickTemplate(idx)}}>
                        <input type="radio" id={`appuse1${d.code}`} name='appuse' value={d.code} defaultChecked={idx === 0}/>
                        <label htmlFor={`appuse1${d.code}`}>
                          <img src={d.fileUrl[0]} alt="앱 유형 이미지" />
                          <h4>{d.name}</h4>
                        </label>
                      </li>
                    ))
                  }
                  {/* <li>
                    <input type="radio" id={`appuse1${d.code}`} name='appuse' />
                    <label htmlFor={`appuse1${d.code}`}>
                    <img src="../img/appuse01.png" alt="앱 유형 이미지" />
                    <h4>템플릿 이름 01</h4>
                    </label>
                    </li>
                    <li>
                    <input type="radio" id="appuse2" name='appuse' />
                    <label htmlFor="appuse2">
                    <img src="../img/appuse06.png" alt="앱 유형 이미지" />
                    <h4>템플릿 이름 02</h4>
                    </label>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className='rightCon'>
            <div className='templateImg'>
              <Slider {...settings} dotsClass="test-css">
                {
                  temImgs.map(t => (
                    <img src={t} key={t} alt="템플릿 이미지 사진" />
                  ))
                }
              </Slider>
            </div>
          </div>
        </div>
      </main>
      
      <footer>
        <div className='btnWrap'>
          <Link to={-1}><button className='off'>이전</button></Link>
          <button>다음</button>
        </div>
      </footer>
      </Form>
    </div>
  )
}


export default CheckList3_1