import React, { useRef, useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';

const Comp = () => {
  return (
    <div>
      hello
    </div>
  )
}

const Playground = () => {
  const list = [1,2,3,4,5];

  const onClickBtn = (e) => {
    console.debug(e.target.name);
  }

  return (
    <div >
      <button name="jh" onClick={onClickBtn}>Hello</button>
    </div>
  );
};


export default Playground;
