import React from 'react'
import { Helmet } from 'react-helmet-async';


const Inquiry = () => {
    return (
        <main>
            <Helmet>
                <title>AiAppㅣ1:1 문의</title>
                <meta name="author" content="mBaaS Inc." />
                <meta name="description" content="1:1 문의 페이지에서 앱 개발 관련 질문이나 서비스 지원을 요청할 수 있습니다."/>
                <meta name="keywords" content="AiApp, 앱 생성, 무료앱 제작, 앱 개발, ,앱 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, mbaas, 에이아이앱, 앱 만드는방법"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
                <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
                <meta property="og:url" content="https://aiapp.help/" />
                <meta property="og:images" content="../../../public/img/opengraph.jpg" />
                <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
                <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
                <meta property="twitter:image" content="../../../public/img/opengraph.jpg"/>
                <meta property="twitter:url" content="https://aiapp.help/" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <section className="storyWrap">
                <div className="width14">
                    <div className="sectTit">
                        <h2 data-aos="fade-up" data-aos-duration="2000">1:1 문의</h2>
                        <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                            AiApp의 서비스가 궁금하신가요?<br />
                            문의 남겨주시면 확인 후 성실히 답변해 드리겠습니다.
                        </p>
                    </div>

                    <div className="inquiryWrap" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700">
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>유형</h3>
                                <select name="" id="">
                                    <option value="">앱 생성 문의</option>
                                    <option value="">앱 기능 관련 문의</option>
                                    <option value="">기획서 관련 문의</option>
                                    <option value="">관리자 기능 관련 문의</option>
                                    <option value="">마케팅 관련 문의</option>
                                    <option value="">제휴 관련 문의</option>
                                    <option value="">광고 관련 문의</option>
                                    <option value="">기타 문의</option>
                                </select>
                            </div>
                            <div className="inBox"></div>
                        </div>
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>제목</h3>
                                <input type="text" placeholder="제목을 입력해 주세요."/>
                            </div>
                            <div className="inBox">
                                <h3>첨부파일</h3>
                                <input type="file" id="file"/>
                                <span>* 첨부파일은 .png, .jpg 유형의 파일만 첨부하실수 있습니다.</span>
                            </div>
                        </div>
                        <div className="inBox">
                            <h3>내용</h3>
                            <textarea name="" id="" cols="30" rows="10" placeholder="내용을 입력해 주세요."></textarea>
                        </div>
                    </div>
                    <div className="BtnWrap" data-aos="fade-up" data-aos-duration="2000" >
                        <button className="Btn">문의하기</button>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Inquiry