import React from 'react'
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async';


const CheckListRe4 = () => {
  // slick 설정
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

  return (
      <div className='checkListReWrap'>
        <Helmet>
          <title>AiAppㅣ앱 생성ㅣ체크리스트 5</title>
          <meta name="author" content="mBaaS Inc." />
          <meta name="description" content="앱 생성 체크리스트 마지막 단계입니다. 앱 이름을 최종 확인 및 설정하여 앱 개발을 완료하세요."/>
          <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
          <meta property="og:type" content="website" />
          <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
          <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
          <meta property="og:url" content="https://aiapp.help/" />
          <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
          <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
          <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
          <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
          <meta property="twitter:url" content="https://aiapp.help/" />
          <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
        <header>
          <button className='back'><img src="../img/icon_backB.png" alt="" />뒤로가기</button>
          <ul className='stepWrap step5'>
            <li className='on'>
              <div></div>
              <h2>정보입력</h2>
            </li>
            <li className='on'>
              <div></div>
              <h2>앱 유형</h2>
            </li>
            <li className='on'>
              <div></div>
              <h2>유사앱 추천</h2>
            </li>
            <li className='on'>
              <div></div>
              <h2>앱 빌드</h2>
            </li>
            <li className='on'>
              <div></div>
              <h2>앱 인증</h2>
            </li>
          </ul>
        </header>
        <main className='checkListCon'>
          <div className='wrap'>
            <div className='leftCon'>
              <div className='sbox'>
                <h2>인증을 위해 앱 이름을 작성해주세요.</h2>
                <div>
                  <h3>앱 이름</h3>
                  <input type="text" placeholder='프로젝트명을 입력해주세요.'/>
                  <p className='description'>앱 이름은 추후 수정이 가능합니다.</p>
                </div>
              </div>
            </div>
            <div className='rightCon'>
              <div className='templateImg'>
                <Slider {...settings} dotsClass="test-css">
                  <img src="../img/templateImg01.png" alt="템플릿 이미지 사진" />
                  <img src="../img/templateImg02.png" alt="템플릿 이미지 사진" />
                  <img src="../img/templateImg01.png" alt="템플릿 이미지 사진" />
                  <img src="../img/templateImg02.png" alt="템플릿 이미지 사진" />
                </Slider>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className='btnWrap'>
            <button className='off'>이전</button>
            <button>인증하기</button>
          </div>
        </footer>
      </div>
  )}

  
export default CheckListRe4