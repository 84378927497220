import { GET_METHOD, POST_METHOD } from "../../const/const";
import {EXPORT_PLAN_DEFINE, PLAN_TEMPLATE_LIST_DEFINE, SAVE_PLAN_DEFINE} from "../../const/defineName";

export const planDefine = {
    [PLAN_TEMPLATE_LIST_DEFINE]: {
        url: "/api/proposal",
        method: GET_METHOD
    },
    [SAVE_PLAN_DEFINE]: {
        url: "/api/proposal/submit",
        method: POST_METHOD
    },
    [EXPORT_PLAN_DEFINE]: {
        method: POST_METHOD,
        url: "/api/proposal/generate"
    }

}