import { createBrowserRouter } from "react-router-dom"
import Layout from "../views/templates/layout"
import Home from "../views/pages/home"
import Service from "../views/pages/service"
import AppList from "../views/pages/appList"
import Guide from "../views/pages/guide"
import Story from "../views/pages/story/story"
import StoryDetail from "../views/pages/story/storyDetail"
import StoryLayout from "../views/templates/storyLayout"
import CheckList from "../views/pages/checkList/checkList"
import CheckListLayout from "../views/templates/checkListLayout"
import CheckList2 from "../views/pages/checkList/checkList2"
import CheckList3 from "../views/pages/checkList/checkList3"
import CheckList4 from "../views/pages/checkList/checkList4"
import AppCreate from "../views/pages/checkList/appCreate"
import Login, { loginAction } from "../views/pages/login"
import Mypage, { getMyInfoLoader } from "../views/pages/mypage/mypage"
import MypageUser, { updateMyInfoAction } from "../views/pages/mypage/mypageUser"
import Inquiry from "../views/pages/inquiry"
import InquiryUser from "../views/pages/inquiryUser"
import Playground from "../views/pages/playground"
import JoinTerms, { joinTermAction } from "../views/pages/JoinTerms"
import JoinInput, { joinAction } from "../views/pages/JoinInput"
import JoinComplete from "../views/pages/JoinComplete"
import AuthLayout from "../views/templates/authLayout"
import Plan, { planAction } from "../views/pages/plan/plan"
import Development from "../views/pages/plan/development"
import PlanLayout, { planLoader } from "../views/templates/planLayout"
import FindId from "../views/pages/FindId"
import FindIdCom from "../views/pages/findidCom"
import FindPassword from "../views/pages/findPassword"
import FindPasswordRe, { resetPwAction } from "../views/pages/FindPasswordRe"
import FindPasswordCom from "../views/pages/findPasswordCom"
import CheckList1, { appUseLoader, checkList1Action } from "../views/pages/checkList/checkList1"
import CheckList2_1, { similarAppLoader } from "../views/pages/checkList/checkList2_1"
import CheckList3_1, { customTemplateAction, customTemplateLoader } from "../views/pages/checkList/checkList3_1"
import CheckList4_1, { customCapabilityListAction, customCapabiliyListLoader } from "../views/pages/checkList/checkList4_1"
import CheckList5_1, { appCreateAction, appCreateLoader } from "../views/pages/checkList/checkList5_1"
import CheckListCustomLayout from "../views/templates/checkListCustomLayout"
import Pdf, { pdfLoader } from "../views/pages/pdf/pdf"
import TotalLayout from "../views/templates/totalLayout"
import Error404 from "../views/pages/error/Error404"
import SocialInput, { socialJoinAction } from "../views/pages/SocialInput"
import SocialTerms, { socialTermAction } from "../views/pages/SocialTerms"
import SocialLoginProc from "../views/pages/socialLoginProc"
import PersonalTerm from "../views/pages/personalTerm"
import ServiceTerm from "../views/pages/serviceTerm"
import Loadng from "../views/pages/loading"


/**
 * 
 * @param {QueryClient} queryClient : react-query를 실행시키는 QueryClient를 index.js에서 받아온다.
 * @returns 
 */
export const createRouter = (queryClient) => {
    return createBrowserRouter([
        {
            path: "/",
            element: <TotalLayout />,
            children: [
                {
                    path: "/login",
                    element: <Login />,
                    action: loginAction(queryClient),
                },
                {
                    path: "/term",
                    element: <JoinTerms />,
                    action: joinTermAction(queryClient)
                },
                {
                    path: "/join",
                    element: <JoinInput />,
                    action: joinAction(queryClient)
                },
                {
                    path: "/joinc",
                    element: <JoinComplete />
                },
                {
                    path: "/sterm",
                    element: <SocialTerms/>,
                    action: socialTermAction()
                },
                {
                    path: "/sjoin",
                    element: <SocialInput/>,
                    action: socialJoinAction(queryClient)
                },
                {
                    path: "/sloginproc",
                    element: <SocialLoginProc/>
                },
                {
                    path: "/findid",
                    element: <FindId />
                },
                {
                    path: "/findidCom",
                    element: <FindIdCom />
                },
                {
                    path: "/findpw",
                    element: <FindPassword />
                },
                {
                    path: "/findpwRe",
                    element: <FindPasswordRe />,
                    action: resetPwAction(queryClient)
                },
                {
                    path: "/findpwCom",
                    element: <FindPasswordCom />
                },
                {
                    path: "/",
                    element: <Layout />,
                    children: [
                        {
                            index: true,
                            element: <Home />
                        },
                        {
                            path: "service",
                            element: <Service />
                        },
                        {
                            path: "guide",
                            element: <Guide />
                        },
                        {
                            path: "applist",
                            element: <AppList />
                        },
                        {
                            path: "info",
                            element: <AuthLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <Mypage />,
                                    loader: getMyInfoLoader(queryClient)
                                },
                                {
                                    path: "user",
                                    element: <MypageUser />,
                                    loader: getMyInfoLoader(queryClient),
                                    action: updateMyInfoAction(queryClient),
                                },
                                {
                                    path: "inquiry",
                                    children: [
                                        {
                                            index: true,
                                            element: <Inquiry />
                                        },
                                        {
                                            path: ":id",
                                            element: <InquiryUser />
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            path: "story",
                            element: <StoryLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <Story />
                                },
                                {
                                    path: ":id",
                                    element: <StoryDetail />
                                }
                            ]
                        },
                        {
                            path: "personalTerm",
                            element: <PersonalTerm/>
                        },
                        {
                            path: "serviceTerm",
                            element: <ServiceTerm/>
                        }
                    ],
                },
                {
                    path: "checklist",
                    element: <CheckListLayout />,
                    children: [
                        {
                            index: true,
                            element: <CheckList />,
                        },
                        {
                            path: "1",
                            element: <CheckList1 />,
                            loader: appUseLoader(queryClient),
                            action: checkList1Action(),
                        },
                        {
                            path: "2",
                            element: <CheckList2 />,
                            loader: similarAppLoader(queryClient)
                        },
                        {
                            path: "3",
                            element: <CheckList3 />
                        },
                        {
                            path: "4",
                            element: <CheckList4 />,
                            loader: appCreateLoader(queryClient),
                        },
                        {
                            path: "appcreate",
                            element: <AppCreate />
                        }
                    ]
                },
                {
                    path: "checklistcustom",
                    element: <CheckListCustomLayout />,
                    children: [
                        {
                            path: "2",
                            element: <CheckList2_1 />,
                            loader: similarAppLoader(queryClient)
                        },
                        {
                            path: "3",
                            element: <CheckList3_1 />,
                            loader: customTemplateLoader(queryClient),
                            action: customTemplateAction(),
                        },
                        {
                            path: "4",
                            element: <CheckList4_1 />,
                            loader: customCapabiliyListLoader(queryClient),
                            action: customCapabilityListAction(),
                        },
                        {
                            path: "5",
                            element: <CheckList5_1 />,
                            loader: appCreateLoader(queryClient),
                            action: appCreateAction(queryClient),
                        },
                        {
                            path: "appCreate",
                            element: <AppCreate />,
                            action: appCreateAction(queryClient)
                        }
                    ]
                },
                {
                    path: "playground",
                    element: <Playground />
                },
                {
                    path: "/plan",
                    element: <PlanLayout />,
                    loader: planLoader(queryClient),
                    children: [
                        {
                            path: "development",
                            element: <Development />
                        },
                        {
                            path: ":id",
                            element: <Plan />,
                            action: planAction(queryClient)
                        },
                    ]
                },
                {
                    path: "pdf",
                    element: <Pdf />,
                    loader: pdfLoader(queryClient),
                },
                {
                    path: "*",
                    element: <Error404/>
                },
                {
                    path : "loading",
                    element : <Loadng/>
                }

            ]
        }
    ])
}