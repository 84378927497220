import React, { useEffect, useRef } from 'react'
import { Form, redirect, useActionData } from 'react-router-dom';
import { MARKETING_AGREE, PERSONAL_AGREE, POST_METHOD, SERVICE_AGREE } from '../../const/const';
import { makeParamsFromFormData } from '../../util/util';
import { Helmet } from 'react-helmet-async';

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns redirect를 return 시킴으로써 해당 URL로 바로 이동시킨다.
 */
export const joinTermAction = 
    () => async({ request }) => {
        const param = makeParamsFromFormData(await request.formData());
        const errors = {};

        // 유효성 검사
        if(!param.memberServiceAgree) {
            errors.memberServiceAgree = "서비스이용 약관은 필수 입니다."
            return errors;
        } else if(!param.memberProfileAgree) {
            errors.memberServiceAgree = "개인정보이용동의 약관은 필수 입니다."
            return errors;
        }
        return redirect(`/join?ms=${param.memberServiceAgree}&mp=${param.memberProfileAgree}&mm=${param.memberMarketingAgree}`);
    }

const JoinTerms = () => {
    const inputRef = useRef([]);
    const errors = useActionData();

    const onClickCheckAll = (e) => {
        inputRef.current.forEach(c => {
            c.checked = e.target.checked
        })
    }

    useEffect(() => {
        if(errors?.memberServiceAgree) {
            inputRef.current[0].focus();
            inputRef.current[0].style.scrollMargin = "90px";
            inputRef.current[0].scrollIntoView();
        } else if(errors?.memberProfileAgree) {
            inputRef.current[1].focus();
            inputRef.current[1].style.scrollMargin = "90px";
            inputRef.current[1].scrollIntoView();
        }
    }, [errors])

    return (
        <main>
        <Helmet>
            <title>AiAppㅣ회원가입ㅣ약관동의</title>
            <meta name="author" content="mBaaS Inc." />
            <meta name="description" content="서비스 이용 약관과 개인정보 처리 방침을 확인하고 동의하여 회원가입을 완료하세요."/>
            <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
            <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
            <meta property="og:url" content="https://aiapp.help/" />
            <meta property="og:images" content="../../../public/img/opengraph.jpg" />
            <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
            <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
            <meta property="twitter:image" content="../../../public/img/opengraph.jpg"/>
            <meta property="twitter:url" content="https://aiapp.help/" />
            <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
            <div className="joinWrap bg2">
                <div className="joinCon">
                    <div>
                        <h1>Join</h1>
                        <ul>
                            <li>
                                <div className='on'>1</div>
                                <p>약관동의</p>
                            </li>
                            <li className='etc'></li>
                            <li>
                                <div>2</div>
                                <p>추가정보 입력</p>
                            </li>
                            <li className='etc'></li>
                            <li>
                                <div>3</div>
                                <p>회원가입 완료</p>
                            </li>
                        </ul>
                        <div className='termWrap'>
                            <div>
                                <div className='all checkboxArea'>
                                    <label htmlFor="all" onClick={onClickCheckAll}>
                                        <input type="checkbox" id="all" />
                                        <p>전체 약관에 동의합니다.</p>
                                    </label>
                                </div>
                                <Form method={POST_METHOD}>
                                    <div>
                                        <div className='checkboxArea'>
                                            <label htmlFor="check1">
                                                <input
                                                    ref={el => inputRef.current[0] = el}
                                                    type="checkbox"
                                                    id="check1"
                                                    name='memberServiceAgree' />
                                                <p>[필수] 서비스 이용약관에 동의합니다.</p>
                                            </label>
                                        </div>
                                        <div className='terms'>{ SERVICE_AGREE }</div>
                                    </div>                           
                                    <div>
                                        <div className='checkboxArea'>
                                            <label htmlFor="check2">
                                                <input
                                                    ref={el => inputRef.current[1] = el}
                                                    type="checkbox"
                                                    id="check2"
                                                    name='memberProfileAgree' />
                                                <p>[필수] 개인정보 처리방침에 동의합니다.</p>
                                            </label>
                                        </div>
                                        <div className='terms'>{ PERSONAL_AGREE }</div>
                                    </div>                            
                                    <div>
                                        <div className='checkboxArea'>
                                            <label htmlFor="check3">
                                                <input
                                                    ref={el => inputRef.current[2] = el}
                                                    type="checkbox"
                                                    id="check3"
                                                    name='memberMarketingAgree' />
                                                <p>[선택] 마케팅 활용방침에 동의합니다.</p>
                                            </label>
                                        </div>
                                        <div className='terms'>{ MARKETING_AGREE }</div>
                                    </div>
                                    <div className='d-flex'>
                                        <button>다음</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default JoinTerms