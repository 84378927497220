import React from "react";

const Loading = () => {
  return (
    <div className="loading">
        <div class="loader-wrap">
            <div class="loader loader-item">
              <div class="loader loader-3"></div>
              <div class="loader loader-3"></div>
              <div class="loader loader-3"></div>
            </div>
        </div>
    </div>
  );
};

export default Loading;
