
// export const FILE_HOST = `https://${process.env.FILE_HOST}`;
// export const HOST = `https://${process.env.HOST}`;
// export const BUILD_HOST = `https://${process.env.BUILD_HOST}`;

export const FILE_HOST = process.env.REACT_APP_FILE_HOST;
export const HOST = process.env.REACT_APP_HOST
export const BUILD_HOST = process.env.REACT_APP_BUILD_HOST
export const IFRAME_HOST = process.env.REACT_APP_IFRAME


// export const HOST = "https://devapi.aiapp.link";

// export const FILE_HOST = "https://devapi.aiapp.link";
// export const HOST = process.env.REACT_APP_HOST_URL;
// export const FILE_HOST = process.env.REACT_APP_FILE_HOST_URL;