import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const Error404 = () => {
    const navigate = useNavigate();  
    const handleBack = () => {
        navigate(-1); //뒤로가기
      };
    // useEffect(() => {
    //     navigator("/")
    // }, [])

    return (
        <div className='error404'>
            <Helmet>
                <title>AiAppㅣError 404</title>
                <meta name="author" content="mBaaS Inc." />
                <meta name="description" content=" Error 404 페이지입니다. 요청하신 페이지를 찾을 수 없습니다. 홈으로 돌아가거나 다른 메뉴를 이용해 주세요."/>
                <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
                <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
                <meta property="og:url" content="https://aiapp.help/" />
                <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
                <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
                <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
                <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
                <meta property="twitter:url" content="https://aiapp.help/" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <h2>Error404</h2>
            <p>페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.<br/>입력하신 주소가 정확한지 다시 한 번 확인해주세요.</p>
            <button onClick={handleBack}>이전페이지로</button>
        </div>
    )
}

export default Error404