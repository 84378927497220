import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const Tab1 = () => {
    return (
        <>
        <Helmet>
        <title>AiAppㅣ스토리</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="최신 소식, 사용자 인터뷰, 공지사항 등 다양한 정보를 확인하고, 앱 개발에 대한 유용한 인사이트를 얻어보세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
            <div id="tab1" class="tab__content active">
                <ul>
                    {/* <li>
                        <div class="storyImg">
                            <img src="../../img/story1.png" alt="스토리 이미지" />
                        </div>
                        <p class="tag">Story</p>
                        <h3>"M뮤"앱을 성공으로 이끈 1인 CEO의 이야기</h3>
                    </li> */}
                    <Link to={"t2ff3d343"}>
                        <li>
                            <div class="storyImg">
                                <img src="../../img/story0.png" alt="스토리 이미지" />
                            </div>
                            <p class="tag">Notice</p>
                            <h3>Aiapp에 오신걸 환영합니다.</h3>
                        </li>
                    </Link>
                    {/* <li>
                        <div class="storyImg">
                            <img src="../../img/story3.png" alt="스토리 이미지" />
                        </div>
                        <p class="tag">News</p>
                        <h3>부산 벤처&창업 ESG선도기업 엠바스 선정</h3>
                    </li>
                    <li>
                        <div class="storyImg">
                            <img src="../../img/story4.png" alt="스토리 이미지" />
                        </div>
                        <p class="tag">Notice</p>
                        <h3>정기 정검 안내 (매주 목요일 11:00 ~ 12:00)</h3>
                    </li> */}
                </ul>
            </div>
            {/* <div class="pageWrap">
                <ul>
                    <li><img src="../img/icon_arrow_leftW.png" alt="페이지 왼쪽 화살표 이미지" /></li>
                    <li class="on">1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li><img src="../img/icon_arrow_rightW.png" alt="페이지 오른쪽 화살표 이미지" /></li>
                </ul>
            </div> */}
        </>
    )
}

const Tab2 = () => {
    return (
        <>
            <div id="tab2" class="tab__content active">
                <div class="nodata">작성된 글이 없습니다.</div>
            </div>
            <div class="pageWrap">
                <ul>
                    <li><img src="../img/icon_arrow_leftW.png" alt="페이지 왼쪽 화살표 이미지" /></li>
                    <li class="on">1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li><img src="../img/icon_arrow_rightW.png" alt="페이지 오른쪽 화살표 이미지" /></li>
                </ul>
            </div>
        </>
    )
}

const Tab3 = () => {
    return (
        <>
            <div id="tab2" class="tab__content active">
                <div class="nodata">작성된 글이 없습니다.</div>
            </div>
            <div class="pageWrap">
                <ul>
                    <li><img src="../img/icon_arrow_leftW.png" alt="페이지 왼쪽 화살표 이미지" /></li>
                    <li class="on">1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li><img src="../img/icon_arrow_rightW.png" alt="페이지 오른쪽 화살표 이미지" /></li>
                </ul>
            </div>
        </>
    )
}

const Tab4 = () => {
    return (
        <>
            <div id="tab2" class="tab__content active">
                <div class="nodata">작성된 글이 없습니다.</div>
            </div>
            <div class="pageWrap">
                <ul>
                    <li><img src="../../img/icon_arrow_leftW.png" alt="페이지 왼쪽 화살표 이미지" /></li>
                    <li class="on">1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li><img src="../../img/icon_arrow_rightW.png" alt="페이지 오른쪽 화살표 이미지" /></li>
                </ul>
            </div>
        </>
    )
}


const renderTabs = (currTab) => {
    switch (currTab) {
        case 0:
            return <Tab1 />
        case 1:
            return <Tab2 />
        case 2:
            return <Tab3 />
        case 3:
            return <Tab4 />
        default:
            return <Tab1 />
    }
}

const Story = () => {
    const [currTab, setCurrTab] = useState(0)
    const tabList = [
        {
            name: "All",
            herf: "",
        },
        {
            name: "Story",
            herf: "",
        },
        {
            name: "News",
            herf: "",
        },
        {
            name: "Notice",
            herf: "",
        },
    ]
    return (
        <main>
            <section class="storyWrap bg2">
                <div class="width14">
                    <div class="sectTit">
                        <h2 data-aos="fade-up" data-aos-duration="2000">AiApp Story</h2>
                        <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                            AiApp의 다양한 소식을 알려드립니다.
                        </p>
                    </div>
                    <div class="d-flex align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="2000"  data-aos-delay="500">
                        <ul class="tab">
                            {
                                tabList.map((tab, i) => (
                                    <li
                                        key={i}
                                        className={`tab__item ${i === currTab ? 'active' : ''}`}
                                        onClick={() => { setCurrTab(i) }}
                                    >
                                        <span>{tab.name}</span>
                                    </li>
                                ))
                            }
                        </ul>
                        <div class="searchWrap" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">
                            <select name="" id="">
                                <option value="title">제목</option>
                                <option value="contents">내용</option>
                            </select>
                            <input type="text" placeholder="검색어를 입력해주세요." />
                            <button><img src="../img/icon_search.png" alt="검색아이콘" /></button>
                        </div>
                    </div>
                    <div class="total" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">Total <span>0</span></div>
                    <div class="tab__content-wrapper" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600">
                        {renderTabs(currTab)}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Story