import React from 'react'
import { appUseQuery } from '../../../queries/checklistQueries'
import { Form, redirect, useLoaderData, useNavigate } from 'react-router-dom';
import { makeParamsFromFormData } from '../../../util/util';
import { POST_METHOD } from '../../../const/const';
import { Helmet } from 'react-helmet-async';

/**
 * 해당 페이지가 랜더링 되기전 Loader가 실행되어 데이터 통신을 마친후 페이지를 랜더링 해준다.
 * @param {QueryClient} queryClient router.js에서 받아온 React-query Client
 * @returns 
 */
export const appUseLoader =
  (queryClient) => async () => {
    if (!queryClient.getQueryData(appUseQuery().queryKey)) {
      return await queryClient.fetchQuery(appUseQuery());
    }

    return queryClient.getQueryData(appUseQuery().queryKey);
  }

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns redirect를 return 시킴으로써 해당 URL로 바로 이동시킨다.
 */
export const checkList1Action =
  () => async ({ request }) => {
    const param = makeParamsFromFormData(await request.formData());

    // 각 앱유형에 유형^값 을 설정해줘서 ^기준으로 나누고 유형별로 다른 url로 리다이렉트
    const type = param.appuse.split("^")[0];
    const val = param.appuse.split("^")[1];
    const name = param.appuse.split("^")[2] ?? "";
    
    if (type === "custom") {
      return redirect(`/checklistcustom/2?s=${(val)}&t=${(type)}&n=${name}`)
    } else {
      return redirect(`/checklist/2?s=${val}&t=${type}&n=`)
      // return redirect(encodeURI(`/checklist/2?s=${val}&t=${type}&n=${name}`))
    }

  }

const CheckList1 = () => {
  const { data } = useLoaderData();
  const navigator = useNavigate();
  
  return (
    <Form method={POST_METHOD}>
      <Helmet>
        <title>AiAppㅣ앱 생성ㅣ체크리스트 1</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="앱 생성 체크리스트 첫 단계입니다. 만들고자 하는 앱의 유형과 목적을 선택하여 맞춤형 앱 개발 과정을 시작하세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='checkListReWrap'>
        <main className='checkListCon'>
          <div className='wrap'>
            <div className='centerCon'>
              <h2>어떤 앱을 만들고 싶나요?</h2>
              <div className='appuseWrap'>
                <div className='abox'>
                  <div className='tit d-flex align-items-center'>
                    <h3>커스터마이징 앱</h3>
                    <div className='info info1'>
                      <p>커스터마이징 앱은 템플릿 및 추가기능을<br/> 직접 선택하실 수 있습니다.</p>
                    </div>
                  </div>
                  <ul>
                    {
                      data.customList.map((c, idx) => (
                        <li key={c.category}>
                          <input
                            type="radio"
                            id={`appuse${c.category}`}
                            name='appuse'
                            defaultChecked={idx === 0}
                            value={`custom^${c.category}^${c.category}`} />
                          <label htmlFor={`appuse${c.category}`}>
                            <img src={c.img} alt="앱 유형 이미지" />
                            <h4>{c.category}</h4>
                          </label>
                        </li>
                      ))
                    }
                  </ul>
                </div>
                <div className='abox'>
                  <div className='tit d-flex align-items-center'>
                    <h3>베이직 앱</h3>
                    <div className='info'>
                      <p>베이직 앱은 템플릿이 고정이며 추가기능을 선택하실 수 없지만, <br/>앱내 게시판을 자유롭게 생성할 수 있습니다.</p>
                    </div>
                  </div>
                  <ul>
                    {
                      data.basic.basicList.map(b => (
                        <li key={b.code}>
                          <input
                            type="radio"
                            id={`appuse${b.code}`}
                            name='appuse'
                            value={`basic^${b.code}^${b.name}`} />
                          <label htmlFor={`appuse${b.code}`}>
                            <img src={b.fileUrl[0]} alt="앱 유형 이미지" />
                            <h4>{b.name}</h4>
                          </label>
                        </li>
                      ))
                    }
                    {/* <li>
                    <input type="radio" id="appuse5" name='appuse' />
                    <label htmlFor="appuse5">
                      <img src="../img/appuse05.png" alt="앱 유형 이미지" />
                      <h4>교회 유형</h4>
                    </label>
                  </li>
                  <li>
                    <input type="radio" id="appuse6" name='appuse' />
                    <label htmlFor="appuse6">
                      <img src="../img/appuse06.png" alt="앱 유형 이미지" />
                      <h4>협회 유형</h4>
                    </label>
                  </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className='btnWrap'>
            <button className='off' onClick={() => {navigator(-1)}}>이전</button>
            <button>다음</button>
          </div>
        </footer>
      </div>
    </Form>
  )
}


export default CheckList1