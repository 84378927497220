import {CHANGE_PLAN_DEFINE, EXPORT_PLAN_DEFINE, GENERATE_PLAN_DEFINE, PLAN_TEMPLATE_LIST_DEFINE, ROLLBACK_PLAN_DEFINE, SAVE_PLAN_DEFINE} from "../const/defineName"
import {fetchBuilder, fetchData, fetchDataPathVar, fetchPDF} from "../util/util"

export const planTemplateListQuery = (param) => ({
    queryKey: ["planTemplateList"],
    queryFn: async() => {
        const json = await fetchDataPathVar(PLAN_TEMPLATE_LIST_DEFINE, param);
        return json;
    }
})

export const savePlanQuery = (param) => ({
    queryKey: ["savePlan"],
    queryFn: async() => {
        const json = await fetchData(SAVE_PLAN_DEFINE, param);
        return json;
    }
})
export const generatePlanQuery = (param) => ({
    queryKey: ["generatePlan"],
    queryFn: async() => {
        const json = await fetchBuilder(GENERATE_PLAN_DEFINE, param);
        return json;
    }
})
export const rollbackPlanQuery = (param) => ({
    queryKey: ["rollbackPlan"],
    queryFn: async() => {
        const json = await fetchBuilder(ROLLBACK_PLAN_DEFINE, param);
        return json;
    }
})
export const changePlanQuery = (param) => ({
    queryKey: ["changePlan"],
    queryFn: async() => {
        const json = await fetchBuilder(CHANGE_PLAN_DEFINE, param);
        return json;
    }
})
export const exportPDFQuery = (param) => ({
    queryKey: ["exportPDF", param],
    queryFn: async () => {
        const response = await fetchPDF(EXPORT_PLAN_DEFINE, param);
        if (!response.ok) {
            throw new Error('PDF 생성에 실패했습니다.');
        }
        const blob = await response.blob();
        return blob;
    }
})