import React from 'react'
import { Helmet } from 'react-helmet-async';


const InquiryUser = () => {
    return (
        <main>
            <Helmet>
                <title>AiAppㅣ1:1 문의</title>
                <meta name="author" content="mBaaS Inc." />
                <meta name="description" content="고객 맞춤형 상담을 통해 신속하고 정확한 지원을 제공합니다."/>
                <meta name="keywords" content="AiApp, 앱 생성, 무료앱 제작, 앱 개발, ,앱 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, mbaas, 에이아이앱, 앱 만드는방법"/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
                <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
                <meta property="og:url" content="https://aiapp.help/" />
                <meta property="og:images" content="../../../public/img/opengraph.jpg" />
                <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
                <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
                <meta property="twitter:image" content="../../../public/img/opengraph.jpg"/>
                <meta property="twitter:url" content="https://aiapp.help/" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <section className="storyWrap">
                <div className="width14">
                    <div className="sectTit">
                        <h2 data-aos="fade-up" data-aos-duration="2000">내 문의</h2>
                    </div>

                    <div className="inquiryWrap user" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700">
                        <h2>내 문의상세</h2>
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>유형</h3>
                                <div>앱생성 문의</div>
                            </div>
                            <div className="inBox">
                                <h3>답변상태</h3>
                                <div>답변완료</div>
                            </div>
                        </div>
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>제목</h3>
                                <div>문의드립니다.</div>
                            </div>
                            <div className="inBox">
                                <h3>첨부파일</h3>
                                <div className="cursor">asd.png</div>
                            </div>
                        </div>
                        <div className="inBox">
                            <h3>내용</h3>
                            <div className="answer">문의 내용입니다. </div>
                        </div>
                        <div className="BtnWrap" data-aos="fade-up" data-aos-duration="2000" >
                            <button className="Btn">마이페이지로</button>
                        </div>
                    </div>

                    <div className="inquiryWrap user" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700">
                        <h2>문의 답변</h2>
                        <div className="inBoxWrap">
                            <div className="inBox">
                                <h3>제목</h3>
                                <div>문의드립니다.</div>
                            </div>
                        </div>
                        <div className="inBox">
                            <h3>내용</h3>
                            <div className="answer">답변내용입니다.</div>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    )
}

export default InquiryUser