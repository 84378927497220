import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const JoinComplete = () => {
    return (
        <main>
        <Helmet>
            <title>AiAppㅣ회원가입 완료</title>
            <meta name="author" content="mBaaS Inc." />
            <meta name="description" content="가입이 성공적으로 완료되었습니다. 이제 다양한 앱 개발 서비스와 기능을 자유롭게 이용해보세요."/>
            <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
            <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
            <meta property="og:url" content="https://aiapp.help/" />
            <meta property="og:images" content="../../../public/img/opengraph.jpg" />
            <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
            <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
            <meta property="twitter:image" content="../../../public/img/opengraph.jpg"/>
            <meta property="twitter:url" content="https://aiapp.help/" />
            <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
            <div className="joinWrap bg1">
                <div className="joinCon">
                    <div>
                        <h1>Join</h1>
                        <ul>
                            <li>
                                <div>1</div>
                                <p>약관동의</p>
                            </li>
                            <li className='etc'></li>
                            <li>
                                <div>2</div>
                                <p>추가정보 입력</p>
                            </li>
                            <li className='etc'></li>
                            <li>
                                <div className='on'>3</div>
                                <p>회원가입 완료</p>
                            </li>
                        </ul>
                        <div>
                            <div className='joincomplete'>
                                <h2>회원가입을 축하드립니다! </h2>
                                <p>로그인을 하고 나만의 앱을 생성해 보세요.</p>
                            </div>
                            <Link to={"/login"}><button>로그인하러가기</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default JoinComplete