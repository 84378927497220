import React from "react"
import { Form, redirect, useActionData, useSearchParams } from "react-router-dom";
import { PHONE_REX, POST_METHOD } from "../../const/const";
import Input from '../atom/Input';
import { decrypt, makeParamsFromFormData } from "../../util/util";
import { socialJoinQuery } from "../../queries/memberQueries";
import { Helmet } from 'react-helmet-async';

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns redirect를 return 시킴으로써 해당 URL로 바로 이동시킨다.
 */
export const socialJoinAction = 
  (queryClient) => async({ request }) => {
    const url = new URL(request.url);
    const memberName = decrypt(url.searchParams.get("memberName"));
    const memberEmail = url.searchParams.get("memberEmail");
    const provider = url.searchParams.get("provider");
    const param = makeParamsFromFormData(await request.formData());
    param.memberName = memberName;
    param.memberEmail = memberEmail;
    param.provider = provider;
    
    const error = {};

    if(!param.memberPhone) {
      error.memberPhone = "전화번호는 필수입니다.";
      return error;
    }
    if (!PHONE_REX.test(param.memberPhone)) {
      error.memberPhone = "전화번호 형식을 확인해주세요.('-'제외)";
      return error;
  }

    const response = queryClient.fetchQuery(socialJoinQuery(param));

    if(response.result === "SUCCESS") {
      
    }
    return redirect("/joinc")
  }

  /**
   * 소셜회원가입이후 부족한 정보를 받기위한 컴포넌트
   * @returns 
   */
const SocialInput = () => {
  const errors = useActionData();
  const [searchParams,] = useSearchParams();
  return (
    <main>
      <Helmet>
        <title>AiAppㅣ소셜 회원가입ㅣ정보입력</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="소셜 회원가입 정보 입력 페이지입니다. 소셜 계정으로 간편하게 가입하고, 빠르게 앱 개발 서비스를 시작하세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="joinWrap bg2">
        <div className="joinCon">
          <h1>Join</h1>
          <ul>
            <li>
              <div>1</div>
              <p>약관동의</p>
            </li>
            <li className='etc'></li>
            <li>
              <div className='on'>2</div>
              <p>추가정보 입력</p>
            </li>
            <li className='etc'></li>
            <li>
              <div>3</div>
              <p>회원가입 완료</p>
            </li>
          </ul>
          <div className='termWrap'>
            <Form method={POST_METHOD}>
              <div>
                <h3>필수사항</h3>
                <input type='hidden' name='memberMarketingAgree' defaultValue={searchParams.get("mm") != "undefined" ? "Y" : "N"} />
                <input type='hidden' name='memberServiceAgree' defaultValue={"Y"} />
                <input type='hidden' name='memberProfileAgree' defaultValue={"Y"} />
                {/* <ul className='list'>
                  <li>
                    <label htmlFor="user03">
                      <input type="radio" name="businessType" id="user03" defaultValue={"PRIVATE"} defaultChecked/>
                        <p>개인사업자</p>
                      </label>
                  </li>
                  <li>
                    <label htmlFor="user02">
                      <input type="radio" name="businessType" id="user02" defaultValue={"FOUNDATION"}/>
                        <p>예비창업자</p>
                      </label>
                  </li>
                  <li>
                    <label htmlFor="user04">
                      <input type="radio" name="businessType" id="user04" defaultValue={"INCORPORATE"}/>
                        <p>법인사업자</p>
                      </label>
                  </li>
                </ul> */}
                {/* <div className='sbox'>
                  <h4>이름</h4>
                  <Input
                    type="text"
                    name='memberName'
                    placeholder='이름을 입력해주세요.' />
                </div> */}
                <div className='sbox'>
                  <h4>연락처</h4>
                  <Input
                    type="text"
                    name='memberPhone'
                    error={errors?.memberPhone}
                    placeholder='휴대폰 번호를 입력해주세요. (- 제외)' />
                </div>
                <div className='btnWrap'>
                  <button className='off'>이전</button>
                  <button>다음</button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </main>
  )
}

export default SocialInput